.markerInfo * {
  color: black;
}
.right-buttons {
  width: 100%;
  text-align: end;
}
.map-pin-details {
  background-color: rgba(148, 146, 146, 0.69);
  padding: 0.75em;
  border-radius: 1em;
}
.gm-style-iw-ch span {
  color: black !important;
  font-size: 1.5em;
  font-weight: bolder;
}

.map-pin-details span {
  color: white;
  vertical-align: middle;
}
.map-pin-detail:hover {
  cursor: pointer;
}

.red-pin {
  color: red !important;
  font-size: 2em;
  vertical-align: middle;
}
.blue-pin {
  color: blue !important;
  font-size: 2em;
  vertical-align: middle;
}
.green-pin {
  color: green !important;
  font-size: 2em;
  vertical-align: middle;
}
.orange-glyph {
  color: rgb(255, 128, 0) !important;
  font-size: 1em;
  vertical-align: middle;
}
.blue-glyph {
  color: rgb(0, 255, 255) !important;
  font-size: 1em;
  vertical-align: middle;
}
.green-glyph {
  color: rgb(51, 255, 0) !important;
  font-size: 1em;
  vertical-align: middle;
}
.estar-icon-map{
  vertical-align: middle;
}
@media only screen and (max-width: 991px) {
  #customersMap {
    width: 90% !important;
    height: 80vh !important;
  }
}

.map-estar-score {
  background-color: blue;
  border-radius: 20%;
  max-height: fit-content;
  font-size: 3.5vh;
  font-weight: bolder;
  display: flex;
  justify-content: center;
}

.map-estar-score .score-title {
  color: white;
  font-size: 0.3em;
  display: flex;
  justify-content: center;
}
.map-estar-score span {
  color: white !important;
  padding-left: 1.2em;
  padding-right: 1.2em;
}
.map-detail-list {
  list-style: none;
  padding: 0;
}

.map-detail-list li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  font-size: 1em;
}

.map-detail-list li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 12px;
  height: 12px;
}

.map-popup-wrapper {
  top: -100px;
  position: absolute;
}

.popup-content * {
  color: black;
}
#map-popup {
  background: #efefef;
  border: 1px solid #a7a7a7;
  border-radius: 4px;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto 10px;
  height: 100px;
  width: 300px;
  padding: 7px;
  position: relative;
  left: 20px;
}

.map-popup-arrow {
  border-left: 21px solid transparent;
  bottom: -25px;
  position: absolute;
  right: 15px;

  &::before {
    bottom: 2px;
    content: "";
    position: absolute;
    right: 67px;
  }
  &::after {
    border-right: 21px solid transparent;
    border-left: 21px solid transparent;
    border-top: 21px solid #efefef;
    bottom: 4px;
    content: "";
    position: absolute;
    right: 65px;
  }
}

.check:before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><polyline points='1 5 4 8 8 1' stroke='green' stroke-width='2' fill='none' /></svg>")
    no-repeat no-repeat;
}
.noCheck:before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><line x1='0' y1='0' x2='10' y2='10' stroke='red' stroke-width='2' /><line x1='0' y2='10' x2='10' y1='0' stroke='red' stroke-width='2' /></svg>")
    no-repeat no-repeat;
}
