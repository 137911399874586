.campus-title{
    position: relative;
    font-size: 1.5vw;
}

.campus-title span{
    vertical-align: bottom;
}
.building-title{
    position: relative;
    font-size: 1.5vw;
}

.building-title span{
    vertical-align: bottom;
}

.top-right-align{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
}

.campus-card p{
    color: black !important;
}

.campus-card:hover{
    cursor: pointer;
}

.card-closed{
    display: none;
}

.card-btn{
    padding: 0;
    margin: 0;
}

.subtitle-closed{
    display: none;
}

.transparent{
    background-color: transparent !important;
}

.dark{
    font-size: 1.2em !important;
    color: black;
}
.card-content{
    margin-left: 2em;
}

.space-left {
    margin-left: 1em;
}

.m-0{
    margin-top: 0;
    margin-bottom: 0;
}

.align-bottom{
    position: relative;
}

.align-bottom span{
    vertical-align: bottom;
}

.ae2-customer-name h1{
    font-size: 3vw;
}
.basic-cust-info h2{
    font-size: 1.5vw;
}
.remote-access-info h2{
    font-size: 1.5vw;
}
.basic-cust-info div{
    font-size: 0.8vw;
}
.basic-cust-info span{
    font-size: 1vw;
    font-weight: 200;
    color: #e6e6e6;
    text-decoration: underline;
}

.ae2-totals-info h2{
    height: 1.5vw;
}
.ae2-totals-info div{
    font-size: 0.8vw;
}
.ae2-totals-info span{
    font-size: 1vw;
    font-weight: 200;
    color: #e6e6e6;
    text-decoration: underline;
    vertical-align: bottom;
}

.remote-access-info div{
    font-size: 0.8vw;
    display: relative;
}
.remote-access-info span{
    font-size: 1vw;
    font-weight: 200;
    color: #e6e6e6;
    text-decoration: underline;
}
.clipboardButton{
   vertical-align: top;
   max-height: 20px;
}

@media only screen and (max-width: 991px) {
    .ae2-customer-name h1{
        width: fit-content;
        font-size: 8vw;
    }
    .basic-cust-info div{
        font-size: 3vw;
    }
    .basic-cust-info h2{
        font-size: 5.5vw;
    }
    .basic-cust-info span{
        font-size: 5vw;
        font-weight: 200;
    }
    .ae2-totals-info{
        text-align: center;
    }
    .ae2-totals-info h2{
        height: 0em;
    }
    .ae2-totals-info div{
        font-size: 3vw;
    }
    .ae2-totals-info span{
        font-size: 4vw;
        font-weight: 200;
    }

    .remote-access-info div{
        font-size: 3vw;
    }
    .remote-access-info h2{
        font-size: 5.5vw;
    }
    .remote-access-info span{
        font-size: 5vw;
        font-weight: 200;
    }
    .campus-title{
        font-size: 5.5vw;
    }
    .building-title{
        font-size: 5.5vw;
    }
    .pc-only{
        display: none;
    }
    .thumbnail-mobile ion-col {
        display: flex;
        justify-content: center;

    }
}

@media screen and (max-width: 600px) {
    .ae2-customer-name h1{
        width: fit-content;
        font-size: 10vw;
    }
    .basic-cust-info div{
        font-size: 5vw;
    }
    .basic-cust-info h2{
        font-size: 7.5vw;
    }
    .basic-cust-info span{
        font-size: 7vw;
        font-weight: 200;
    }
    .ae2-totals-info{
        text-align: center;
    }
    .ae2-totals-info h2{
        height: 0em;
    }
    .ae2-totals-info div{
        font-size: 5vw;
    }
    .ae2-totals-info span{
        font-size: 5.5vw;
        font-weight: 200;
    }

    .remote-access-info div{
        font-size: 5vw;
    }
    .remote-access-info h2{
        font-size: 7.5vw;
    }
    .remote-access-info span{
        font-size: 6vw;
        font-weight: 200;
    }
    .campus-title{
        font-size: 7.5vw;
    }
    .building-title{
        font-size: 7.5vw;
    }

}