.estar-home, .ion-page{
    --background: var(--ion-item-background, var(--ion-background-color, #0A2533)) !important;
  }

.error-detail{
    color: red;
    margin-right: 4em;
    font-size: medium;
  }

.invalid{
    border: red solid 2px;
}

.form-container{
  width: 100%;
  justify-content: center;
  display: flex;
}

.form-title-row{
  width: 100%;
  justify-content: center;
  position: relative
}
.form-title-row ion-button{
  margin-top: 1em;
  margin-right: 1em;
}

.cancel-btn{
  position: absolute;
  top: 5px;
  right:1em;
}

.light{
  color: white;
}

.edit-warning, .delete-warning{
  text-align: center;
  width: 100%;
  color: red;
  font-size: 1.5em;
  background-color: white;
  border-radius: 2em;
  padding: 0.8em;
}