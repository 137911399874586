.Customers-list, .Buildings-list, .Campuses-list, .about-list {
    display: none;

}

#labels-list{
    overflow: auto;
    max-height:68%;
    padding: 0.5em;
}

#labels-list::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#labels-list::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#labels-list::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.ios .labels-list{
    max-height: 50vh !important;
}

.labels-list{
	background-color: transparent !important;
}


@media screen and (max-height: 1250px)   {
	#labels-list{
		max-height:70%;
	}
}
@media screen and (max-height: 1000px)   {
	#labels-list{
		max-height: 60%;
	}
}
@media screen and (max-height: 850px)   {
	#labels-list{
		max-height: 50%;
	}
}