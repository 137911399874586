.stories-table{
    border: 2px solid black;
}
.stories-table .tr{
    display:flex;
    width: fit-content;
}
.stories-table .td{
    padding: 0.5em;
    box-shadow: inset 0 0 0 1px #5e5d5d;
}
.stories-table .th{
    box-shadow: inset 0 0 0 1px #000000;
    background-color: #4a4a4a;
}
.stories-table .th{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey.400;
    padding: 0.5rem;
    font-weight: bold;
    font-size: small;
    text-transform: uppercase;
    text-align: center;
}
.stories-table .date-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.stories-table .delete-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.stories-table .resizer{
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 5px;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    border-radius: 6px;
}
.stories-table .resizer.isResizing{
    background-color: aqua;
    opacity: 1;
}

*:hover > .stories-table .resizer {
    opacity: 1;
}

.keyword-box{
    margin: .15em;
    /* padding: .2em;  */
    background-color: #4a4a4a;
}

@media only screen and (max-width: 1950px) {
    #success-stories-table-wrapper{
        overflow-x: auto;
    }
}
