.ae2-customer {
    color: white !important;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }


  .form-container form{
    /* border: red 2px solid; */
    min-width:100%
  }

  .form-container {
    width: 100%;
  }

  @media screen and (min-width: 1100px) {
    .form-container form{
      min-width:70%;
    }
  }

.ae2-customer-content, .ion-page{
  --background: var(--ion-item-background, var(--ion-background-color, #0A2533)) !important;
}

