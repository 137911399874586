.user-info{
    display:block;
    --background: transparent !important;
}

.user-info .username{
    font-size: 1.5rem !important;
}
.user-info .email{
    font-size: 1rem !important;
}
.user-info{
    margin: 5px;
    overflow: hidden;
    white-space: nowrap;
}
