ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #0aa6e9c5));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#menu-list {
  border-bottom: 1.5px solid var(--ion-color-step-150, #000000c5);
  margin-bottom: 1em;
}

ion-menu.md ion-list#menu-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #000000;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
  margin-bottom: 0.2em;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-medium-rgb),0.75);
  color: white;
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-tertiary-rgb);
}

ion-menu.md ion-item ion-icon {
  color: white;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
  margin-bottom: 0.5em;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
  margin-bottom: 0.1em;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: white;

}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-tertiary-rgb);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --background: rgba(var(--ion-color-medium-rgb),0.75);
  color: white;
}

.logout-button{
  position: absolute;
  bottom: 10px;
  width: 95%;
}

#menu-list{
  background-color: transparent ;
}

#menu-list ion-note{
  color: white;
  font-size: larger;
}

#menu-list  ion-list-header{
  font: bolder;
  font-style: italic;
  font-size: 45px;
}

ion-menu ion-item {
  --background: rgba(var(--ion-color-primary-rgb),0.75);
  color: rgba(var(--ion-color-primary-contrast-rgb),0.75);
  font: bold;
}


ion-icon.ae2-icon{
  border: red 2px sold;
}

.ae2-logo{
  width: 50px;
  height: 50px;
}

.logo-container{
  display: flex;
  margin-left: 0.5em;
}

ion-split-pane{
  --side-max-width:400px;
}
@media screen and (max-width: 1600px) {
  ion-split-pane{
    --side-max-width:250px;
  }
}