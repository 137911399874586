
#success-stories-search{
    width: 50%;
}

@media only screen and (max-width: 1400px) {
    #success-stories-search{
        width: 75%;
    }
}
@media only screen and (max-width: 991px) {
    #success-stories-search{
        width: 75%;
    }
}
@media only screen and (max-width: 990px) {
    #success-stories-search{
        width: 100%;
    }
}