.ms-button{
    width: 70% !important;
}

.ms-div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-section{
    display: flex;
    justify-content: center;
}

.login ion-card-header{
    width: 100%;
}

.login-card{
    background-color: #05adf6c5;
}

.login{
    background-color: #00b3ffc5;
    padding-bottom: 2em;
}

.login ion-input{
    color: black;
    background-color: white;
}

.errors{
    color: red;
    font-size: larger;
    font-style: italic;
    font-weight: bold;
}

.submit-btn{
    width: 50%;
}
.login-container{
    display: flex;
    flex-direction: column;
}

.login-privacy-terms{
    padding-top: 1em;
    color:white;
}
