.analytics-card{
    border: 0.5px solid #000000;
    box-shadow: 5px 5px 5px #ffffff44;
    padding : 15px;
    margin: 0.2vw;
    background-color: #0aa6e9;
    border-radius: 10px;
    max-width: 350px;
  }

.analytics-card ion-card-header{
    color: white;
    /* font-size: 1.5em; */
}