.setting-row{
    /* border: red 3px solid; */
    padding: 1em;
    border-bottom: 1px solid rgb(56, 56, 56);
    font-size: larger;

}

.modal-form ion-content{
    --background:rgb(65, 64, 64) !important;
}

.modal-form ion-input{
    background-color: white;
    height: 5px!important;
}
.modal-form ion-label{
    font-size: 2em !important;
    margin-bottom: 0.2em !important;
}
.modal-form form{
    width: 100% !important;
    border: #0aa6e9c5 2px solid;
    padding: 1em;
    border-radius: 1em;
    height: 100%;
    vertical-align: middle;

}
.failed.estar-result-title span{
  background-color: white;
}

.modal-btns{
    justify-content: center;
}
.modal-close{
    justify-content: right;
}

.header{
   padding-right: 1em;
}

.error-detail{
    color: red;
}

.invalid{
    border: 2px solid red;
}
/* Loading Spinner CSS */
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }