.basic-campus-info .customer-name {
    font-size: larger;

}

.customer-name a{
    text-decoration: underline;
    color: white;
}
.customer-name a:hover{
    text-decoration: underline;
    color: black !important
}

.ae2-campus-name h1{
    font-size: 3vw;
}
.basic-campus-info h2{
    font-size: 1.5vw;
}
.basic-campus-info div{
    font-size: 0.8vw;
}
.basic-campus-info span{
    font-size: 1vw;
    font-weight: 200;
    color: #e6e6e6;
    text-decoration: underline;
}
.building-title span{
    font-size: 1.5vw;
}

/* Pop up when copied to clipboard */
.popuptext {
    visibility: hidden;
    width: 80px;
    background-color: white;
    color:black !important;
    text-align: center;
    border-radius: 6px;
    padding: 6px 0;
    position: absolute;
    z-index: 1;
    bottom: 45%;
    left: 45%;
    font-size: 0.5em !important;
    margin-left: -50px;
    text-decoration: none !important;
  }

  /* Toggle this class - hide and show the popup */
.popup .show {
    visibility: visible;
    animation: fade 3s;
  }


  /* Add animation (fade in the popup) */
  @keyframes fade {
    0%,100% { opacity: 0 }
    50% { opacity: 1 }
  }



@media only screen and (max-width: 991px) {
    .ae2-campus-name h1{
        font-size: 8vw;
        width: fit-content;
    }
    .basic-campus-info h2{
        font-size: 5.5vw;
    }
    .basic-campus-info div{
        width: fit-content;
        font-size: 3vw;
    }
    .basic-campus-info span{
        font-size: 5vw;
        font-weight: 200;
    }
    .building-title span{
        font-size: 5.5vw;
    }
    #score-dashboard{
        min-height: 300px;
        max-height: 350px;
      }
}

@media screen and (max-width: 600px) {
    .ae2-campus-name h1{
        font-size: 10vw;
        width: fit-content;
    }
    .building-title span{
        font-size: 7vw;
    }
    .basic-campus-info h2{
        font-size: 7.5vw;
    }
    .basic-campus-info div{
        font-size: 5vw;
    }
    .basic-campus-info span{
        font-size: 6vw;
        font-weight: 200;
    }
}