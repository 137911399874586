.software-list{
    flex-direction: column;
    /* width: 80%; */
}
.release-notes-modal ion-content{
    --background: var(--ion-item-background, var(--ion-background-color, #0A2533));
}
.download-link{
    text-decoration: underline;
}
.notes-link{
    text-decoration: underline;
}
.download-link:hover{
    cursor: pointer;
}
.notes-link{
    cursor: pointer;
}

.software-list a{
    color: white;
    text-decoration: underline;
}

.software-releases-list{
    min-width: 80%;
    overflow: auto;

}

.software-releases-list .release-list{
    min-width: 750px;

}

.software-releases-list .list-button{
    --background:transparent;
    display: flex;
    text-align: center;
    align-items: center;
}

.software-releases-list .list-button ion-icon{
    color: white;

}

.software-releases-list .table-header{
    border-bottom: 1px solid black;

}
.software-releases-list .table-row{
    border-bottom: 1px solid black;

}
.document-container{
    background-color: white;
}
.document-container{
    background-color: white;
    border-radius: 1em;
    border: 1.5px solid black;
    width: 100%;
    margin:0.2em;
}
.document-container >*{
    color: black;
}

.software-info li{
    margin: 0.5em;
    font-size: larger;
}

.software-info {
    margin: 0.5em;
    font-size: larger;
}
.docs-list li{
    margin: 0.6em;
    font-size: 2em;
}
.docs-list a{
    color: white;
    text-decoration: none;
}
.docs-list a:hover{
    color: black;
    text-decoration: underline;
}