.utility-service-table .header{
    border: rgb(75, 75, 75) 1.25px solid;
    text-align: center;
    background-color: rgb(0, 0, 0);
}
.utility-service-table .col{
    border: black 1.25px solid;
    text-align: center;
    flex-direction: row;
}
.utility-service-table .asterisk{
    font-size: small;
    padding-left: 20px;
}

.edit-toggle ion-label{
    padding-left: 0 !important;
    margin: 0;
}
.edit-toggle ion-toggle{
    padding-left: 5px !important;
    padding-right: 0px !important;
}

.confirmDelete{
    --background:#555;
    --height:fit-content;
}

.confirmDelete ion-grid{
    margin: 0.5em !important;
}