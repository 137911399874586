#storyModal, .techModal{
    --background:#555;
    --height:fit-content;
}
#storyModal .buttons{
    display: flex;
    justify-content: right;
}
#storyModal ion-select{
    background-color: transparent;
    color:white
}
#storyModal ion-header{
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
}
#storyModal form{
    width: 100%;
}
#technologyModal{
    --background:#555;
    --height:fit-content;
}
#technologyModal .buttons{
    display: flex;
    justify-content: right;
}
#technologyModal ion-header{
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
}
#technologyModal form{
    width: 100%;
}