.ebo-table ion-col{
    border-bottom: solid 4px rgb(44, 162, 183);
    border-right: solid 4px rgb(44, 162, 183);
}
.ebo-table .heading-col{
    border-top: solid 4px rgb(44, 162, 183);;
}

.ebo-table a{
    text-decoration: none;
    color: white;
}
.ebo-table a:hover{
    text-decoration: underline;
    color: white;
}
.ebo-table-mobile a{
    text-decoration: none;
    color: black;
}
.ebo-table-mobile a:hover{
    text-decoration: underline;
    color: black;
}


.ebo-table-mobile{
    width:100%;
}

.ebo-accordion li{
    color: black !important;
    list-style: none;
}
.ebo-accordion ion-accordion ion-item{
    background-color: rgb(212, 212, 212);
    --background: transparent;
    border-bottom: 2px black solid;
}
.ebo-accordion ion-accordion div[slot="content"] {
    background: rgba(var(--ion-color-rose-rgb), 0.25);
    border-bottom: 3px solid grey;
  }

.cpu-ok{
    color: rgb(7, 255, 7) !important;
  }
.cpu-warning{
    color: rgb(255, 166, 0) !important;
  }
.cpu-alert{
    color: red !important;
  }