.basic-building-info .customer-name {
    font-size: larger;

}

.customer-name a{
    text-decoration: underline;
    color: white;
}
.campus-name a{
    text-decoration: underline;
    color: white;
}
.customer-name a:hover{
    text-decoration: underline;
    color: black !important
}

.campus-name a:hover{

    color: black !important
}

.align-bottom{
    position: relative;
}

.align-bottom span{
    vertical-align: bottom;
}

.ae2-building-name h1{
    font-size: 3vw;
}

.ae2-building-subheading {
    font-size: 1.5vw;
}
.basic-building-info div{
    font-size: 0.8vw;
    padding-left: 1em;
}
.basic-building-info span{
    font-size: 1vw;
    font-weight: 200;
    color: #e6e6e6;
    text-decoration: underline;
}
.utility-title span {
    font-size: 1.5vw;
}
.campus-title span{
    font-size: 1.5vw;
}
.score-container ion-img{
    min-width: 40% ;
}
.score-container .score{
    font-size: 2vw;
}
.score-container .value{
    font-size: 1.6vw;
}
/* .score-dashboard-container{
    min-height: 500px;
    max-height: 600px;
} */
/* #score-dashboard{
    height: 100%;
} */


@media only screen and (max-width: 1400px) {
    .ae2-building-name h1{
        font-size: 7vw;
        width: fit-content;
    }

    .ae2-building-subheading {
        font-size: 3.5vw;
    }
    .basic-building-info div{
        font-size: 1.5vw;
        text-align: left;
    }
    .basic-building-info span{
        font-size: 1.8vw;
    }
    .score-container ion-img{
        min-width: 50% ;
    }
    .score-container .score{
        font-size: 3vw;
    }
    .score-container .value{
        font-size: 2.6vw;
    }
}

@media only screen and (max-width: 991px) {
    .ae2-building-name h1{
        font-size: 8vw;
        width: fit-content;
    }

    .ae2-building-subheading {
        font-size: 5.5vw;
    }
    .basic-building-info div{
        font-size: 3vw;
        text-align: left;
    }
    .basic-building-info span{
        font-size: 5vw;
    }
    .score-container ion-img{
        min-width: 60% ;
    }
    .utility-title span {
        font-size: 5.5vw;
    }
    .campus-title span{
        font-size: 5.5vw;
    }
    .score-container .score{
        font-size: 7vw;
    }
    .score-container .value{
        font-size: 6.4vw;
    }
}

@media screen and (max-width: 600px) {
    .ae2-building-name h1{
        font-size: 10vw;
        width: fit-content;
    }

    .ae2-building-subheading {
        font-size: 7.5vw;
    }
    .utility-title span {
        font-size: 7vw;
    }
    .basic-building-info div{
        font-size: 5vw;
    }
    .basic-building-info span{
        font-size: 6vw;
    }
    .campus-title span{
        font-size: 7vw;
    }
    .score-container .score{
        font-size: 15vw;
    }
    .score-container .value{
        font-size: 14vw;
    }
}

/* Pop up when copied to clipboard */
.popuptext-building {
    visibility: hidden;
    width: 80px;
    background-color: white;
    color:black !important;
    text-align: center;
    border-radius: 6px;
    padding: 6px 0;
    position: absolute;
    z-index: 100;
    font-size: 0.5em !important;
    margin-left: 2rem;
    text-decoration: none !important;
  }
