.ae2-content {
    --background: var(--ion-item-background, var(--ion-background-color, #0A2533));
    --color: white !important;
  }
.main-background {
    --background: var(--ion-item-background, var(--ion-background-color, #0A2533));
    --color: white !important;
  }

.top-header, .top-header.ios {
        --background: var(--ion-item-background, var(--ion-background-color, #0aa6e9c5)) !important;
}

.site-title{
    display: block !important;
}