.form-submit-button{
    --border-radius: 3em;
    text-align: center;
    width: 50% !important;
}

.button-container{
    display: flex;
    justify-content: center;
}

.invalid{
    border: red solid 2px;
}

.form-container ion-item{
    background-color: transparent;
    --background: transparent;
    color:white;
    width: 100%;
    min-height: 75px;
    border-bottom: none;
  }
  .form-container ion-label{
    min-height: 25px;
    font-size: larger;
  }
  .form-container ion-input{
    --background: white;
    border-radius: 0.3em;
    color: black;
    margin-top:0.2em;
    margin-bottom:0.2em;

  }
  .form-container ion-textarea{
    --background: white;
    border-radius: 0.3em;
    color: black;
    margin-top:0.2em;
    margin-bottom:0.2em;

  }
  .form-container ion-select{
     background: white;
    border-radius: 0.3em;
    color: black;
    margin-top:0.2em;
    margin-bottom:0.2em;

  }


  .error-detail{
    color: red;
    margin-right: 4em;
    font-size: medium;
  }