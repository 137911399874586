@media screen and (min-width: 800px) {
    .login-container{
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center !important;
        text-align: center !important;
    }
    .login-card{
        width: 40%;
        box-shadow: slategrey 3px 5px 10px;
        padding:0.5em;
    }

    .ms-button{
        width: 60% !important;
    }
    .icon{
        margin-left: 1em;
        max-width: 50px;
        max-height: 50px;
    }
}

.icon{
    margin-left: 1em;
    max-width: 35px;
    max-height: 35px;
}

.toolbar{
    display: flex;
    font-size: larger;
    font-weight: bolder;
}

ion-toolbar{
    --background: var(--ion-item-background, var(--ion-background-color, #0aa6e9c5)) !important;

}

.login-pg ion-content{
    --background: var(--ion-item-background, var(--ion-background-color, #0A2533)) !important;
}
