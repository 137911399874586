.right-align {
  display: flex;
  align-items: right;
  text-align: right;
  justify-content: flex-end;
}

.testing {
  border: red solid 3px;
}

.vertical-center {
  display: flex;
  align-items: center;
}
.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.modal-background ion-content {
  --background: rgb(65, 64, 64) !important;
}
.modal-background {
  --background: rgb(65, 64, 64) !important;
}
.failed {
  color: rgb(255, 47, 0);
  --color: rgb(255, 47, 0);
}
.failed span {
  color: rgb(255, 47, 0);
  --color: rgb(255, 47, 0);
}
.success span,
.updated span,
.consumption.added span,
.created span {
  color: rgb(17, 177, 11);
  --color: rgb(17, 177, 11);
}
form ion-input {
  --border-radius: 1em;
}
.border-left {
  border-left: 2px solid black;
}
.border-right {
  border-right: 2px solid black;
}
.border {
  border: 2px solid black;
}
.header {
  border: black solid 2px;
  --background: rgb(0, 0, 0) !important;
  background-color: rgb(56, 56, 56);
}

.fit-popover {
  --width: fit-content;
  max-width: 100% !important;
}

[hidden] {
  display: none !important;
}
.username {
  display: flex;
}

.onlineStatus {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 0.3em;
  margin-left: 0.3em;
  margin-top: 0.2em;
}

.onlineStatus.online {
  background-color: rgb(84, 230, 84);
}
.onlineStatus.offline {
  background-color: red;
}

.password-field .invalid {
  border: none;
}
.password-checklist .invalid {
  border: none;
}

.div-link {
  text-decoration: underline;
}

.div-link:hover {
  cursor: pointer;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.privacy-policy *, .terms-conditions *{
  color: black;
}
.privacy-policy a, .terms-conditions a{
  color: blue;
}
