.estar-home, .ion-page{
    --background: var(--ion-item-background, var(--ion-background-color, #0A2533)) !important;
  }

.estar-home {
  --color: white;
}

.status-icon{
  font-size: larger;
}

.connected{
  color: rgb(84, 230, 84);
}

.not-connected{
  color:red
}

.connection-status{
  display: flex;
  align-items:center;
  justify-content: center;
}

.connection-status h5{
  font-size: larger;
  font-weight: bolder;
}

.connection-container{
  display: flex;
  justify-content: center;
}

.request-container{
  border: rgb(54, 54, 54) solid 4px;
  height: 100%;
  border-radius: 2em;
  padding: 1em;
}

.request-container h2 {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: solid white 4px;
  box-shadow: white 3px;
}

.request-container p {
  font-size: larger;
}

.noRequests{
  width: 100%;
  text-align: center;
}

.requests-list{
  background-color: grey;
}
.requests-list ion-item{
  --background: transparent;
  color: white;
}

.estar_banner{
  min-width: 60%;
}

.overview-card{
  border: black solid 2px;
  text-align: center;
  margin: 1em;
  --background: transparent ;
  width: 75%;
}
.overview-card p {
  text-align: center;
  margin: 1em;
}

.overview-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.overview-cards ion-label {
  font-size: 1.2vw !important;
  color: white!important;
}
.overview-cards p {
  font-size: 1.2vw !important;
  color: white!important;
}
.overview-card-spacer{
  height: 10em;
}
.connection-status-pc{
  display: block;
  float:bottom;
  --background:transparent;
  position: fixed;
  --border-style: none;
  bottom: 0;
  left: 0;
  font-size: 1.5em;
}
.connection-status-pc h5{
  font-size: 1em;
}

.connection-status-mobile{
  display: none;
  --background: transparent;
}

.estar-home-grid{
  position: relative;
}

.mobile-only{
  display: none;
}

.es_banner_container{
  display: flex;
  justify-content: center;
}

@media screen and (min-width:1200px) {
  .request-container{
    margin-right: 2em;
  }
}

@media screen and (max-width:992px) {
  .overview-cards p {
    font-size: 2.2vw !important;
  }
  .overview-cards ion-label {
    font-size: 2.2vw !important;
  }
  .overview-card {
    min-width: 80%;
  }
  .mobile-only{
    display: block;
  }
  .estar-home .ios.in-toolbar{
    min-height: 60px !important;
  }
  .overview-card-spacer{
    height: 2px;
  }
  .connection-status-pc{
    display: none;
  }
  .connection-status-mobile{
    display: block;
  }
  .connection-status-mobile h5 {
    font-size: 2.2em;
  }
  .connection-status-mobile ion-icon {
    font-size: 2.2em;
  }

}


@media screen and (max-width: 800px) {
  .estar_banner{
    min-width: 100%;
  }
  .estar_banner ion-img{
    object-fit: cover;
  }
  .overview-cards p {
    font-size: 6.2vw !important;
  }
  .overview-cards ion-label {
    font-size: 6.2vw !important;
  }
  .estar-home .ios.in-toolbar{
    min-height: auto !important;
  }
  .connection-status-mobile h5 {
    font-size: 1.2em;
  }
  .connection-status-mobile ion-icon {
    font-size: 1.2em;
  }
}