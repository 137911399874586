.homepage-data{
    text-align: center;
}
.homepage-totals-title{
    border-top: none !important;
    text-align: center;
    color: black;
    font-weight: bolder;
}
.homepage-totals-table{
    text-align: center;
}
.homepage-totals-table ion-row{
    border-bottom: rgb(88, 85, 85) 2px solid;
    font-size: 1.2em;
    padding: 0.25em;
}

.homepage-customers, .homepage-databases {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homepage-customers ion-img{
    /* display: flex !important;
    justify-content: center !important; */
    margin: 0 auto;
    padding-top: 0.5em;
}

.homepage-customer-img{
    min-width: 20%;
    min-height: 240px;
}

.ae2-title{
    font-size: 3.8em;
    font-weight: bolder;
    text-decoration: underline;
    width: 100%;
    margin: 0 auto;
    padding: 1vw;
}

/* .homepage-customer-btn{
    display: block;
    max-width: 50%;
} */

.homepage-database-img{
    max-width: 25%;
}

.homepage-databases ion-img{
    margin: 0 auto;
}

.homepage-title{
    font-size: 4em;
}

.homepage-data p {
    margin: 0px;
    margin-top: 1em;
    font-size: 1.5em;
}

.homepage-customers ion-button{
    font-size: 1.5em;
}
.homepage-databases ion-button{
    font-size: 1.5em;
}

.ae2_banner{
    min-width: 100%;
}

.homepage-top h2{
    width: 100%;
    text-align: center;
    font-size: 2em;
    font-weight: bold;
}

.homepage-top div{
    width: 100%;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    margin: 0.2em
}

.homepage-totals h2{
    font-size: 2em;
    width: 100%;
}
.homepage-totals-row ion-row{
    font-size: 1.5em;
}
.homepage-totals-row h2{
    width: 100%;
    text-align: center;
    font-size: 1.5em;
}
.homepage-metrics li{
    text-decoration: none;
    list-style: none;
    font-size: 1.3em;
}
.homepage-metrics ul{
    margin-left: 0;
    padding-left: 0;
}
.homepage-metrics-row, .homepage-totals-row{
    width: 100%;
}
.homepage-metrics ion-title{
    /* text-decoration: underline */
    border-bottom: 3px solid white;
    font-size: 1.5em;
}
.homepage-metrics .score{
    font-size: 1.3em;
}
.homepage-metrics a{
    text-decoration: underline;
    color: white!important;
}
.homepage-metrics a:hover{

    color: blue!important;
}

.border-2{
    border-bottom: 1px solid rgb(45, 45, 45);
    border-right: 1px solid rgb(45, 45, 45);
}
.homepage-metrics .top{
    border-top: 1px solid rgb(45, 45, 45);
}

.homepage-metrics .left-row{
    font-size: 1.6em;
    font-weight: bold;
    border-left: 1px solid rgb(45, 45, 45);
}
.homepage-metrics .top-row{
    font-size: 1.6em;
    font-weight: bold;
}
.homepage-metrics .data{
    font-size: 1.3em;
}


@media screen and (max-width: 1800px) {
    .homepage-metrics .left-row{
        font-size: 1em;
    }
    .homepage-metrics .top-row{
        font-size: 1.2em;
    }
    .homepage-metrics .data{
        font-size: 1em;
    }
}
@media screen and (max-width: 1200px) {
    .homepage-metrics .left-row{
        font-size: 0.7em;
    }
    .homepage-metrics .top-row{
        font-size: 1em;
    }
    .homepage-metrics .data{
        font-size: 0.8em;
    }
}



@media screen and (max-width: 991px) {
    .homepage-customer-img{
        max-width: 100%;
        /* max-height: 480px; */
    }

    .homepage-database-img{
        max-width: 75%;
    }
    .ae2-title{
        font-size: 2.6em !important;
    }

    .homepage-data p {
        margin: 0px;
        margin-top: 1em;
        font-size: 1.2em;
    }
    .homepage-title{
        font-size: 2em;
    }
    .homepage-customer-img{
        padding-top: 0.2em;
    }
    .homepage-customers ion-button{
        font-size: 1em;
    }
    .homepage-databases ion-button{
        font-size: 1em;
    }
    .homepage-top h2{
        font-size: 1.5em;
    }

    .homepage-top div{
        font-size: 1em;
    }

    .homepage-totals-row h2{
        font-size: 1.5em;
    }
    .homepage-totals-row ion-row{
        font-size: 1.2em;
    }
    .homepage-totals-row{
        margin-bottom: 0em;
    }

    .homepage-metrics .left-row{
        font-size: 0.7em;
    }
    .homepage-metrics .top-row{
        font-size: 1em;
    }
    .homepage-metrics .data{
        font-size: 0.8em;
    }

  }

