.score-container{
    text-align: center;
    width: 100%;
    justify-content: center;
}
.score-container p{
    margin-left: 0px;
}


.score-container > ion-img {
    width: 25%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.score-container > .score{
    width: 100;
    text-align: center;
}

.score-form > ion-input {
    background-color: white;
    color: black;
    border-radius: 0.3em;
    margin-right: 0.5em;
}

.score-form{
    display: flex;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}