.table-heading{
    border: 2px solid grey;
}
.table-row{
    border-bottom: 2px solid grey;
    border-left: 2px solid grey;
    border-right: 2px solid grey;
}
.right-border-grey{
    border-right: 2px solid grey;
}
.left-border-grey{
    border-left: 2px solid grey;
}
.error{
    border: 3px solid red;
}