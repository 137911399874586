.http-403{
    font-size:13em;
}
.http-404{
    font-size:15em;
}

.http-msg{
    width: 100%;
    text-align: center;
}