.container, .ion-page{
    --background: var(--ion-item-background, var(--ion-background-color, #0A2533)) !important;
  }

  .mobile-title{
    display: none;
    font-size: 6vw;
    width: 100%;
  }
  .pc-title{
    font-size: 3em;
    display: block;
  }

  .pc-title-content{
    position: absolute;
    bottom: 0px;
  }

  .ios.title-default{
    align-items: left !important;
    padding-inline: 0px !important;
  }

  .header-logo{
    float: right;
    max-width: 75px;
    max-height: 75px;
  }

  .site-title{
    font-size: 3em;
  }


  @media screen and (max-width: 800px) {
    .mobile-title{
      display: block;
    }
    .pc-title{
      display: none;
    }
    .site-title{
      font-size: 5.5vw;
    }

  }