.text-center {
    text-align: center;
}

.main-heading{
    font-size: 3vw;
}

@media only screen and (max-width: 1400px) {
    .main-heading{
        font-size: 7vw;
        width: fit-content;
    }
}


@media only screen and (max-width: 991px) {
    .main-heading{
        font-size: 8vw;
        width: fit-content;
    }
}