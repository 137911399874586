.estar-meter{
    --background: var(--ion-item-background, var(--ion-background-color, #0A2533)) !important;
  }

.estar-meter {
  --color: white;
}

.meter-info{
  font-size: 20px;
}