.estar-property, .ion-page{
    --background: var(--ion-item-background, var(--ion-background-color, #0A2533)) !important;
  }

.estar-property {
  --color: white;
}


.top-title{
    border-bottom: 3px black dashed;
    justify-content: space-between;
}

.top-title div{
    bottom: 0;
}
.top-title ion-button{
    margin:0.1em;
    bottom: 0;
    padding: 0;
}

.top-title h1{
    font-size: 2.25em;
    position: relative;
}

.top-title h1 a{
    position: absolute;
    top:0px;
}

@media screen and (max-width:992px) {
    .property-page-content .top-title{
        display: flex;
        justify-content: center !important;
    }
}
.basic-info > div > p{
    color: white;
    padding:1px;
    margin: 1px;
}

.basic-info{
    border: black 1px solid;
    border-radius: 3em;
    background-color: rgb(59, 56, 56);
    padding: 1em;
}

.basic-info > h4{
    border-bottom: 1px white solid;
    font-size: 1.75em;
}

.basic-info > div {
    padding-left: 3em;
    font-size: large;
}


.uses-cards{
    display: flex;
    flex-wrap: wrap;
}

.prop-uses-container h4 {
    font-size: 1.75em;
    margin-left: 0.5em;
}

.prop-uses-title {
    display: flex;
}

.prop-uses-title ion-button{
    margin-bottom: 1.5em;
    margin-top: auto;
}


.uses-card{
    color:white !important;
    border-radius: 2em;
    padding: 1em;
    background-color: #0dcaf0;
    box-shadow: 1px 4px 14px 2px rgb(215, 216, 216);
    margin: 0.8em;
}

.card-title, .card-subtitle, .card-content{
    --color: black;
}

.card-title{
    font-size: 26px;
    text-decoration: underline;
}

.uses-card:hover{
    cursor: pointer;
}

.desc-item, .detail-header, .detail-list, .detail-ion-list{
    --background: transparent;
    color: black;
}
.detail-header{
    border-bottom: 1px solid black;
    color: black;;
}

.detail-list, .desc-item, .detail-ion-list{
    background-color: transparent;
}

.detail-ion-list{
    /* border: black 2px solid; */
    border-top: none;
}

.modal-button{
    width: 100%;
    display: flex;
    justify-content: right;
}