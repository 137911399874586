.baseline-content form{
    min-width: 100%;
    min-height: 100%;
    padding: 1.5em;
}
.baseline-content ion-header{
    font-size: 25px;
    margin-bottom: 0.2em;;
    color: white;
}

.baseline-content ion-input{
    color: black;
    background-color: aliceblue;
    min-width: 100% !important;
}
.baseline-content ion-select{
    color: black;
    background-color: aliceblue;
    min-width: 100% !important;
}
.checkbox-label{
    font-size: 20px !important;
    color: white !important;
}
.baseline-button{
    justify-content: center;
}