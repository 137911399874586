.form-submit-button{
    --border-radius: 3em;
    text-align: center;
    width: 50% !important;
}

.button-container{
    display: flex;
    justify-content: center;
}

.invalid{
    border: red solid 2px;
}

form ion-item{
  background-color: transparent;
  --background: transparent;
  color:white;
  width: 100%;
  min-height: 75px;
  border-bottom: none;
}
form ion-label{
  min-height: 25px;
  font-size: larger;
}
form ion-input{
  --background: white;
  border-radius: 0.3em;
  color: black;
  margin-top:0.2em;
  margin-bottom:0.2em;
}
form ion-textarea{
  --background: white;
  border-radius: 0.3em;
  color: black;
  margin-top:0.2em;
  margin-bottom:0.2em;
}
form ion-select{
   background: white;
  border-radius: 0.3em;
  color: black;
  margin-top:0.2em;
  margin-bottom:0.2em;
  width: 100% !important;
}


.error-detail{
  color: red;
 margin-right: 4em;
 font-size: medium;
}

@media screen and (min-width:1200px){
    form{
        width: 75%;
    }

}
h2, h3, p{
    color: white;
}

.address-row{
    width: 100%;
}

.address-row-2 ion-col{
    padding: 0.75em;
}
p{
    margin-left: 3em;
}