.admin-utility{
    font-size: larger;
    color: white;
}

.utility-settings ion-list{
    background-color: transparent;

}

.utility-settings ion-item{
    margin:0.2em;

}

#providerModal, #unitsModal{
    --background:#555;
    --height:fit-content;
}

#providerModal .buttons{
    display: flex;
    justify-content: right;
}
#providerModal ion-header{
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
}
#unitsModal .buttons{
    display: flex;
    justify-content: right;
}
#unitsModal ion-header{
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
}

#serviceTypeModal form{
    width: 100%;
}

#serviceTypeModal{
    --background:#555;
    --height:fit-content;
}

#serviceTypeModal .buttons{
    display: flex;
    justify-content: right;
}
#serviceTypeModal ion-header{
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
}

#serviceTypeModal form{
    width: 100%;
}
#unitsModal form{
    width: 100%;
}

.category-item{
    --background: #c3c2c2;
    --padding-bottom: 0px;
    --inner-padding-bottom:0px;
    --margin-bottom: 0px;
}
.category-item-row{
    padding-bottom: 0px;
    --padding-bottom: 0px;
    --inner-padding-bottom:0px;
    margin-bottom: 0px;
}

.category-item:hover{
    cursor: pointer;

}