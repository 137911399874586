.prop-table{
    color: whitesmoke;
}
.prop-table ion-list{
    background-color: rgb(48, 44, 44);
    min-width: 60%;
    border-radius: 1em;
    padding: 0.5em;
}

.prop-row{
    text-align: center;
    border-bottom: 3px solid black;
    background-color: transparent;
    padding: 0.5em;

}

.prop-row:hover{
    background-color: black;
    cursor: pointer;
}

.prop-row-title ion-col{
    text-align: center;
    border: 2px solid black;
    background-color: transparent;
    padding: 0.5em;
    font-weight: bolder;
    font-size: larger;
}

.prop-table a {
    text-decoration: none !important;
    list-style: none;
    color: white;
}

.top-row{
    justify-content: space-between;
}

.title{
    width: 100%;
    text-align: center;
    margin: 1em;
    font-size: 2.5em;
    font-weight: bolder;
}

.homepage-properties-table .prop-table ion-list{
    min-width: 100%;
}


/* Sizing for it in the home page of energy star */

.estar-home .prop-table .prop-row{
    font-size: 0.9vw;
}
.estar-home .prop-table .prop-row-title{
    font-size: 1vw;
    background-color: grey;
    color: black;
}
.estar-home .prop-table .title{
    text-decoration: underline;
}


@media screen and (max-width:992px) {
    .estar-home .prop-table .prop-row{
        font-size: 3vw;
    }

    .estar-home .prop-table .prop-row-title{
        font-size: 3vw;

    }
}
