.bills-buttons{
    justify-content: space-between;
    margin: 0.4em;
}

.bills-buttons a{
    color: white !important;
    text-decoration: none;
}

.del-button{
    --background: transparent
}
