.alert-red{
    background-color: red;
    /* --background:red; */
}
.reapr-accordion ion-accordion ion-item{
    background-color: rgb(212, 212, 212);
    --background: transparent;
    border-bottom: 2px black solid;
}
.alert-red ion-accordion ion-item{
    background-color: red;
    --background: transparent;
}
.reapr-accordion ion-accordion div[slot="content"] {
    color: black !important;
    background: rgba(var(--ion-color-rose-rgb), 0.25);
    border-bottom: 3px solid grey;
  }
.reapr-status-row ion-col{
    font-size: 1.5em;
}

.reapr-system-title{
    border-bottom: none !important;
}

.reapr-table ion-col{
    border-bottom: solid 4px rgb(44, 162, 183);
    padding: 0.5em;
    vertical-align: middle;

}
.reapr-table .heading-col{
    border-top: solid 4px rgb(44, 162, 183);
    border-right: solid 4px rgb(44, 162, 183);
}

.reapr-table a{
    text-decoration-thickness: 0.01em;
    vertical-align: middle;
}
.reapr-table-mobile a{
    color: black;
    text-decoration-thickness: 0.01em;
}

.reapr-table-mobile{
    width: 100% !important;
}