.degree-days-table ion-col{
    border-bottom: solid 4px rgb(44, 162, 183);
    border-right: solid 4px rgb(44, 162, 183);
}
.degree-days-table ion-row .heading-col{
    border-top: solid 4px rgb(44, 162, 183);
}
.heading-col{
    /* border-top: solid 4px rgb(44, 162, 183); */
    text-align: center;
    font-weight: bold;
    font-size: larger;
}

.degree-days-table a{
    color: white;
    text-decoration: none;
}

.degree-days-table a:hover{
    text-decoration: underline;
}

.degree-days-table .border-left{
    border-left: solid 4px rgb(44, 162, 183);
}

.degree-days-table .data-row ion-col{
    display: flex;
    justify-content: center;
    align-items: center;
}

.degree-days-table {
    overflow-x: auto;
    min-width: 815px;
}

.dd-accordion li{
    color: black !important;
    list-style: none;
}
.dd-accordion ion-accordion ion-item{
    background-color: rgb(212, 212, 212);
    --background: transparent;
}
.dd-accordion ion-accordion #content{
    background-color: rgb(172, 172, 172) !important;
    --background: red !important;
}

.DD-list{
    display: flex;
    width: 100%;
}

.DD-list ion-col{
    display: flex;
    justify-content: center;
}

.DD-list li{
    font-size: 1.25em;
}


.DD-desktop-data li{
    font-size: 1.2em;
    list-style: none;
}
.DD-desktop-data ion-title{
    text-decoration: underline;
}

.DD-desktop-data li:before {
    content: '\2023';
}

@media only screen and (max-width: 1400px) {
    .degree-days-table ion-col{
        font-size: 1em;
    }
    .degree-days-table ion-col a{
        font-size: 1em;
    }
}

@media only screen and (max-width: 991px) {
    .degree-days-table ion-col{
        font-size: 0.7em;
    }
    .degree-days-table ion-col a{
        font-size: 0.7em;
    }
}

