.energy-performance-menu-button.selected{
    background-color:grey;
}

.energy-performance-menu-buttons{
    padding-bottom: 0;
    margin-bottom: 0;
}

.energy-performance-menu-buttons .button{
    padding-bottom: 0;
    margin-bottom: 0;
}

.button-bottom-right{
    position: relative;
    /* border: red 2px solid; */
}
.button-bottom-right ion-button{
    position: absolute;
    bottom: 0;
    right:4px;
}
