.performance-table ion-row{
    border-bottom: solid rgb(77, 76, 76) 1px;
}
.performance-table ion-col{
    border-right: rgb(77, 76, 76) 1px solid;
    font-size: larger;
}
.performance-table-title ion-col{
    font-size: 1.5em;
    color: rgb(255, 255, 255);
    font-weight: bolder;
}
.left-border{
    border-left: rgb(77, 76, 76) 1px solid;
}
.performance-table-container{
    overflow-y: auto;
}
.performance-table {
    min-width: 1000px;
}