.open-proposal-row {
  overflow-y: auto;
}
.open-proposal-row ion-item {
  background-color: transparent;
  --background: transparent;
}

.open-proposal-row ion-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.open-proposal-row ion-card-content {
  padding-top: 0px;
  padding-bottom: 0.1em;
  text-align: center;
}

.open-proposal-row ion-card {
  min-width: 10em;
  max-width: 250px;
}

.open-proposal-row ion-card:hover {
  cursor: pointer;
  background-color: #97e0ffd6;
}

.open-proposal-row .selected {
  background-color: #0aa6e9c5;
  color: white;
}
